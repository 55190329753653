@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700&display=swap");
@import "~keen-slider/keen-slider.min.css";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Almarai, sans-serif;
  behavior: smooth;
  list-style: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}
body {
  min-height: 100vh;
}

a {
  text-decoration: none;
}

nav {
  width: 100%;
  position: fixed;
  top: 28px;
  left: 0;
  background-color: white;
  border-bottom: 1px solid rgb(0 0 0 / 4%);
  z-index: 1000;
}

.blure {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: white;
  color: #000;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
}

.overlay__login {
  background-color: rgb(0 0 0 / 32%);
}
.item {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mbsc-windows.mbsc-textfield-wrapper {
  margin: 0 !important;
}
.mbsc-windows.mbsc-textfield {
  border: 0px solid rgb(209 213 219) !important;
  border-radius: 6px !important;
  max-height: 40px !important;
  padding: 0 0 !important;
}
.custom-shape-divider-bottom-1713022184 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1713022184 svg {
  position: relative;
  display: block;
  width: calc(178% + 1.3px);
  height: 72px;
  border: none;
}

.custom-shape-divider-bottom-1713022184 .shape-fill {
  fill: #ffffff;
  border: none;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1713022184 svg {
    width: calc(158% + 1.3px);
    height: 44px;
  }
}

.mySwiper {
  padding: 20px 5px !important;
}

.spinner {
  animation: spin 2.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.datePicker {
  width: 100%;
  display: block;
  height: 45px;
}

.react-datepicker__input-container {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  padding: 0 10px;

  border: 1px solid rgb(209 213 219);
  border-radius: 7px;
  background-color: white;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  border: none;
  outline: none;
  background-color: white;
}
.MuiInputBase-root {
  background-color: white !important;
}
.css-lrgvhg {
  height: 50px !important;
  max-height: 50px !important;
  background-color: white;
}
.css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root {
  height: 50px !important;
  max-height: 50px !important;
}

.dot {
  position: relative;
}

.dot::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 19px;
  height: 100%;
  width: 2px;
  background-color: black;
}
.dot_2::after {
  content: "";
  position: absolute;
  top: -50%;
  left: 19px;
  height: 100%;
  width: 2px;
  background-color: black;
}

/* Remove arrows in Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: 2px solid #4f46e5;
  outline-offset: -1px;
} */

select option:disabled {
  color: #cccccc; /* Light gray text */
  background-color: #e6e6e6; /* Light gray background (optional) */
}
